<template>
  <div class="pt-3">
    <div class="d-flex">
      <div class="mr-auto p-2"><h1 class="title">Vertrekken</h1></div>
      <div class="p-2">
        <b-dropdown right text="Download" variant="light">
          <b-dropdown-item @click="downloadBookings('pdf')">PDF</b-dropdown-item>
          <b-dropdown-item @click="downloadBookings('xlsx')">Excel</b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="p-2">
        <v-date-picker
          mode="range"
          is-required
          locale="nl"
          :step="1"
          :input-props="{
            class: 'appearance-none border py-2',
            readonly: true
          }"
          :attributes="[
            {
              key: 'today',
              dot: true,
              dates: new Date()
            }
          ]"
          :rows="1"
          :columns="2"
          :value="{ start: startDate, end: endDate }"
          :update-on-input="false"
          :popover="{ placement: 'bottom', visibility: 'click' }"
          @input="onDateRangeUpdated($event)"
        >
          <b-button-group class="w-min-vc">
            <b-input-group-prepend>
              <b-button variant="light" title="Selecteer periode">
                <i class="far fa-calendar-alt"></i>
              </b-button>
              <b-button variant="light" @click.stop="onDateRangePrevious">
                <i class="fa fa-chevron-left"></i>
              </b-button>
            </b-input-group-prepend>
            <b-input-group-text slot="append" class="date-range w-100"> {{ startDate | moment("ll") }} - {{ endDate | moment("ll") }} </b-input-group-text>
            <b-input-group-append>
              <b-button variant="light" @click.stop="onDateRangeNext">
                <i class="fa fa-chevron-right"></i>
              </b-button>
              <b-button variant="light" title="Deze periode" @click.stop="onDateRangeReset">
                <i class="far fa-calendar-check"></i>
              </b-button>
            </b-input-group-append>
          </b-button-group>
        </v-date-picker>
      </div>
    </div>

    <loading-spinner v-if="loading" :loading="loading" />

    <b-table
      show-empty
      empty-text="Geen boekingen om weer te geven"
      :items="bookings"
      :fields="fields"
      :current-page="pageIndex"
      :per-page="pageCount"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      hover
      @row-clicked="onRowClicked"
    >
      <template #cell(bookingNumber)="row">
        <b-row>
          <b-col
            >{{ row.item.bookingNumber }}<br />
            <booking-status :booking="row.item"
          /></b-col>
        </b-row>
      </template>
      <template #cell(lastName)="row">
        {{ row.item.lastName }}, {{ row.item.firstName }}
        <br />
        <small class="text-blue">{{ row.item.accommodation }}</small>
      </template>
      <template #cell(startDate)="row">
        {{ row.item.startDate | moment("DD-MM-YYYY") }} - {{ row.item.endDate | moment("DD-MM-YYYY") }}
        <br />
        <small class="text-blue">{{ row.item.nightCount }} nachten</small>
      </template>
      <template #cell(guestCount)="row">
        {{ row.item.equipe }}
        <br />
        <small class="text-blue">{{ row.item.nightSum }} nachten</small>
      </template>
      <template #cell(pitches)="row">
        {{ row.value || $tc("reports.not_assigned") }}
        <br />
        <small class="text-blue">{{ row.item.registrationPlate }}</small>
      </template>
      <template #cell(checkInDate)="row">
        <span v-if="row.item.checkInDate">{{ row.item.checkInDate | moment("DD-MM-YYYY") }}</span>
        <br />
        <small v-if="row.item.checkInDate" class="text-blue">{{ row.item.checkInDate | moment("hh:mm uur") }}</small>
      </template>
      <template #cell(checkOutDate)="row">
        <span v-if="row.item.checkOutDate">{{ row.item.checkOutDate | moment("DD-MM-YYYY") }}</span>
        <br />
        <small v-if="row.item.checkOutDate" class="text-blue">{{ row.item.checkOutDate | moment("hh:mm uur") }}</small>
      </template>
      <template #cell(paymentStatus)="row">
        <payment-status :booking="row.item" />
      </template>
    </b-table>
    <b-row v-if="totalPages > 1">
      <b-col cols="12" class="my-1">
        <b-pagination v-model="pageIndex" :total-rows="totalRows" :per-page="pageCount" class="my-0 float-right" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FileSaver from "file-saver";
import ContentDisposition from "content-disposition";
import config from "@/config";
import { DateHelpers } from "@/helpers";
import LoadingSpinner from "@/components/common/LoadingSpinner.vue";
import BookingStatus from "@/components/common/Status/BookingStatus";
import PaymentStatus from "@/components/common/Status/PaymentStatus";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
const BookingRepository = RepositoryFactory.get("BookingRepository");

export default {
  components: { LoadingSpinner, BookingStatus, PaymentStatus },

  data() {
    return {
      config: config,
      bookings: [],
      fields: [
        {
          key: "bookingNumber",
          label: "#",
          sortable: true
        },
        {
          key: "lastName",
          label: "Naam",
          sortable: true
        },
        {
          key: "pitches",
          label: "Plaats",
          sortable: true,
          formatter: (value, key, item) => item.pitches.join(",")
        },
        {
          key: "startDate",
          label: "Verblijf",
          sortable: true,
          sortDirection: "asc"
        },
        {
          key: "guestCount",
          label: "Equipe"
        },
        {
          key: "checkInDate",
          label: "Aangekomen",
          sortable: true
        },
        {
          key: "checkOutDate",
          label: "Vertrokken",
          sortable: true
        },
        {
          key: "paymentStatus",
          label: "Status",
          sortable: true
        }
      ],
      pageIndex: 1,
      pageCount: 10,
      sortBy: null,
      sortDesc: false,
      sortDirection: "asc",
      startDate: this.$moment().startOf("day").toDate(),
      endDate: this.$moment().endOf("day").toDate(),
      loading: false
    };
  },

  computed: {
    ...mapState("Campsites", ["currentCampsite"]),
    totalRows: function () {
      return this.bookings.length;
    },
    totalPages: function () {
      return Math.ceil(this.bookings.length / this.pageCount);
    }
  },

  watch: {
    bookings: {
      handler() {
        this.pageIndex = 1;
      }
    }
  },

  mounted() {
    this.getBookings();
  },

  methods: {
    getBookings() {
      this.loading = true;

      BookingRepository.getDepartures(this.currentCampsite.id, this.startDate, this.endDate, 0, 1000)
        .then((bookings) => {
          this.bookings = bookings;
        })
        .catch((error) => {
          this.$notify({
            group: "app",
            type: "error",
            title: this.$i18n.t("general_error_title"),
            text: error.response.data.message
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    downloadBookings(fileType) {
      this.loading = true;

      BookingRepository.downloadDepartures(this.currentCampsite.id, this.startDate, this.endDate, fileType)
        .then((response) => {
          var cd = ContentDisposition.parse(response.headers["content-disposition"]);
          FileSaver.saveAs(new Blob([response.data]), cd.parameters.filename);
        })
        .catch((error) => {
          this.$notify({
            group: "app",
            type: "error",
            title: this.$i18n.t("general_error_title"),
            text: error.response.data.message
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onRowClicked(booking) {
      this.$router.push({
        name: "booking-detail",
        params: { id: booking.id }
      });
    },
    onDateRangeUpdated(range) {
      this.startDate = this.$moment(range.start).startOf("day").toDate();
      this.endDate = this.$moment(range.end).endOf("day").toDate();
      this.getBookings();
    },
    onDateRangeReset() {
      this.startDate = this.$moment().startOf("day").toDate();
      this.endDate = this.$moment().endOf("day").toDate();
      this.getBookings();
    },
    onDateRangeNext() {
      var range = DateHelpers.getNextDateRange({ startDate: this.startDate, endDate: this.endDate });
      this.startDate = range.startDate;
      this.endDate = range.endDate;
      this.getBookings();
    },
    onDateRangePrevious() {
      var range = DateHelpers.getPreviousDateRange({ startDate: this.startDate, endDate: this.endDate });
      this.startDate = range.startDate;
      this.endDate = range.endDate;
      this.getBookings();
    }
  }
};
</script>
